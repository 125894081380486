/* You can add global styles to this file, and also import other style files */
@import "@coreui/coreui/scss/coreui";
@import 'bootstrap/scss/bootstrap';
@import "../node_modules/ngx-toastr/toastr.css";

html, body { height: 100%; }
body { margin: 0; font-family: "Gothic720BT-BoldB", "Gothic720 BT Bold", "Gothic720 BT Roman", "Gothic720 BT", sans-serif; }

//theme layout css
app-dashboard {
  min-height: 88vh;
}
.app-body{
  height:100%;
}
.app-body .main{
  background:#fff;
  height:100%;
}
/*Custom Scroll-bar*/
/* width */
::-webkit-scrollbar {
    width: 7px;
    height:7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000;
  }

  //firefox
  ::-moz-webkit-scrollbar {
    width: 7px;
    height:7px;
  }
  
  /* Track */
  ::-moz-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
   
  /* Handle */
  ::-moz-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-moz-webkit-scrollbar-thumb:hover {
    background: #000;
  }

  //Focus Elements 
  .form-control.active.focus,
.form-control.active:focus,
.form-control.focus,
.form-control.focus:active,
.form-control:active:focus,
.form-control:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  //background-image: none  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
  border:1px solid #e4e7ea !important;
}
.mdc-button.active.focus,
.mdc-button.active:focus,
.mdc-button.focus,
.mdc-button.focus:active,
.mdc-button:active:focus,
.mdc-button:focus {
  outline: 0 !important;
  outline-offset: 0  !important;
  -webkit-box-shadow: none !important;
  box-shadow: none  !important;
  border:1px solid transparent !important;
  background: transparent;
}

// Form Validation Errors
.invalid-feedback{
    display: block !important;
    position: relative;
    margin-top: 0% !important;
    margin-left: 1%;
  }
  .error-input{
      border:1px solid red !important;
  }

  //Button override css
  button.btn.btn-primary {
    min-width: 160px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 2px 2px 2px;
    font-size: 15px;
    background: rgba(207, 218, 223, 1);
    border: 1px solid rgba(207, 218, 223, 1);
    color: #000;
    font-weight: 400;
    cursor: pointer;
}
button.btn.active-btn {
    background: rgb(23 71 158) !important;
    border:1px solid rgb(23 71 158) !important;
    color:#fff !important;
}
.forgot-btn{
  background: transparent !important;
  border:1px solid transparent !important;
  color:#17479e !important;
  width:120px;
  font-weight: 700 !important;
}
.register-btn{
  background: rgb(219 219 219) !important;
  border:1px solid rgb(219 219 219) !important;
  color:#17479e !important;
  font-weight: 700 !important;
  min-width: 120px !important;
}
.register-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top:15px;
}
.active-btn{
  width: 100%;
}
.overflow-hidden{
    overflow: hidden;
}
.inner-addon.left-addon {
  position: relative;
}
//toaster css
.toast-container .ngx-toastr{
    padding:8px 15px 3px 50px !important;
    font-size:13px !important;
  }

  /* Default position for larger screens */
.toast-top-right .toast-error {
  top: 269px;
  right: 567px;
}

/* Adjust position for smaller screens (e.g., mobile devices) */
@media only screen and (min-width: 320px) and (max-width:1100px)
and (orientation : portrait)  {
  .toast-top-right .toast-error {
    top: 20px;
    right: 10px;
  }
}

  
  //container css
  .top-container{
    margin-top:20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-toggler-icon{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  }
  
  //main container 
  #main-content {
    min-height: calc(100vh - 22vh);
    border-radius: 0px;
    margin-bottom: 3% !important;
    padding-bottom: 5% !important;
    position: relative;
    border:0px;
    margin-top:5px;
  }
  //card css
  .card-header {
    padding: 2px 20px !important;
    height: 33px;
    background: #fff !important;
    border-radius: 0px !important;
    color:#17479e !important;
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.34901960784313724);
  }
  .card-title{
    font-size: 16px;
    font-weight: 700;
  }
  .app-header .nav-item{
    min-width: 35px !important;
  }
  //footer fixed css
.outbound-footer{
    height: 45px;
    background: #c7cbcc;
    border: 1px solid #c7cbcc;
    position: fixed;
    z-index: 1000;
    bottom: 30px;
    padding:6px 10px;
  }
  
  button.btn.btn-transparent {
    color: #17479e;
    font-size: 13px;
    font-weight: 700;
  }
  
  //Table css
  .table-container {
    width: 100%;
    margin-top: 10px;
    overflow-x: auto;
  }
  .table thead th {
    vertical-align: bottom;
    border: 1px solid #ccc;
    background: #cedae0 !important;
    padding: 5px 10px;
    font-size: 13px !important;
    font-weight: 500;
  }
  .table tbody td{
      padding: 5px;
      font-size: 13px;
      border: 1px solid #ccc;
  }
  .btn{
    font-size: 13px !important;
    padding: 5px !important;
  }
  select.form-control{
    appearance: auto !important;
  }
  .mat-error{
    color:red;
    font-size: 12px;
  }
  .login-main-block{
    background-image: url('assets/images/u0.png') !important;
    background: #fff;
  }
  .fa-user-icon{
    background-image: url('assets/images/u22.png');
    width:20px;
    height:20px;
    background-repeat: no-repeat;
  }
  .fa-envelope-icon{
    background-image: url('assets/images/u23.png');
    width:20px;
    height:12px;
    background-repeat: no-repeat;
  }
  .fa-lock-icon{
    background-image: url('assets/images/u11.png');
    width:20px;
    height:20px;
    background-repeat: no-repeat;
  }
  .user-icon{
    background-image: url('assets/images/u5.svg');
    width:20px;
    height:20px;
    background-repeat: no-repeat;
  }
  .fa-angle-down-icon{
    background-image: url('assets/images/u6.svg');
    width:20px;
    height:19px;
    background-repeat: no-repeat;
  }
  .close-btn:hover{
    background: transparent !important;
  }
  .mat-mdc-header-row{
    height:36px !important
  }
  .mat-mdc-row{
    height:36px !important
  }
  .mat-mdc-paginator-container{
    min-height: auto !important;
  }
  .mdc-data-table__row:last-child .mat-mdc-cell{
    border-bottom: 1px solid #ccc !important;
    vertical-align: middle;
  }
  .mdc-data-table__cell{
    padding-left:10px !important;
  }
  .table{
    margin-bottom: 0px !important;
  }
  .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 30px !important;
  }
  .action-btn{
    min-height: 20px !important;
  }
  .mat-mdc-menu-item-text{
    font-size: 12px !important;
  }
  .mat-mdc-option{
    min-height:35px !important;
  }

  //Modal css
.modal-header{
  padding: 8px 18px !important;
  background: #17479e;
  color: #fff;
  height: 43px;
}
.modal-title{
  font-size: 16px !important;
  font-weight: 600;
}
.modal-footer{
  padding:8px 13px !important;
  justify-content: space-between !important;
}
.modal-body{
  max-height: 300px;
  overflow-y: auto;
}
.close{
  opacity:1 !important;
  color: #fff !important; 
}
.close:hover{
  color:#8a939a !important;
}
.asterick{
  color: #a80e14;
  margin-left: 1px;
}

//form css
.form-label{
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1px;
}
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form-field {
  width: 100%;
}
.modal .form-control {
  padding: 5px 10px !important;
  font-size: 13px;
  margin-bottom: 5px;
}
.cancel-btn{
  background: #fff !important;
  color: #000 !important;
  display: inline-flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #17479e !important;
  min-width: 85px;
  font-weight: 700 !important;
}
.save-btn{
  background: #17479e !important;
  color: #fff !important;
  display: inline-flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #797979 !important;
    min-width: 85px;
}
//confirm modal css
.confirm-sm{
  max-width: 350px !important;
}
.confirm-header{
  background: transparent;
    border: 0px !important;
}
.confirm-header .close{
  color: #000 !important;
}
.warning-block {
  display: block;
  width: 100%;
  text-align: center;
}
.warning-icon {
  color: orange;
  font-size: 35px !important;
}
.warning-text {
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin-bottom: 15px;
}
.footer-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
#confirmModal{
  top:22%;
}
.grey-btn{
  background: #8a939a !important;
  color: #000 !important;
  display: inline-flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #797979 !important;
  min-width: 85px;
}

//mat toggle css
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color: #fff !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: #73b842 !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #a3001b !important;
}
.no-data {
  width: 100%;
  padding: 6px 10px;
  display: block;
  text-align: center;
  font-size: 14px;
  background: #fff;
  border: 1px solid #ccc;
}
.refresh-icon{
  cursor: pointer;
}

//Toggle css

.toggle {
  --width: 55px;
  --height: 25px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
  margin-bottom: 0px;
  position: relative;
  top: 5px;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: #ccc;
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--height));
  height: calc(var(--height));
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background-color: #21a108;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 11px;
  font-family: sans-serif;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  right: 4px;
  color: #4d4d4d;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
  top:-3px;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  left: calc(var(--height) - var(--width) + 8px);
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  top:-3px;
}

.toggle input:checked~.labels::after {
  opacity: 0;
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle input:checked~.labels::before {
  opacity: 1;
  transform: translateX(calc(var(--width) - var(--height)));
}
.primary-action-button{
    background-color:#17479e !important;
    height:32px;
}
.card-footer{
  position: fixed;
  width: 100%;
  bottom: 7%;
  z-index:1000;
  background: #f2f2f2 !important;
}
.header-fixed .app-body{
  margin-top: 35px !important;
}
.login-footer .app-body{
  margin-top: 0px !important;
}
.fa-classified-icon{
  background: url("assets/images/u152.png");
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.fa-payment-history-icon{
  background: url("assets/images/u158.png");
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.widget-item{
  background-size: 100%;
}
.status-pending{
  background:url('assets/images/u25.png') left 100% top no-repeat, linear-gradient(241.90347255505063deg, rgba(245, 154, 35, 1) 21%, rgba(245, 154, 35, 1) 93%);
}
.status-approved{
  background:url('assets/images/u25.png') left 100% top no-repeat, linear-gradient(241.90347255505063deg, rgba(149, 242, 4, 1) 21%, rgba(99, 161, 3, 1) 93%);
}
.status-disapproved{
  background:url('assets/images/u25.png') left 100% top no-repeat, linear-gradient(241.90347255505063deg, rgba(217, 0, 27, 1) 21%, rgba(145, 0, 18, 1) 93%);
}
.cart-block{
  width:40px;
  height: 30px;
  background-image: url("assets/images/u16.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right:10px;
  cursor: pointer;
}
.cart-count{
  width:20px;
  height: 20px;
  background-image: url("assets/images/u17.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.mdc-button__label{
  display:flex;
  align-items: center;
}

button.close:focus{
  outline: none !important;
}
textarea.form-control{
  font-size: 13px !important;
  min-height: 80px !important;
}
.table tbody td{
  vertical-align: middle !important;
}
.mat-mdc-button[disabled], .mat-mdc-unelevated-button[disabled], .mat-mdc-raised-button[disabled], .mat-mdc-outlined-button[disabled]{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.8;
  color: #fff !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid{
  background-position: right calc(.875em + .1975rem) center !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #17479e !important;
  background-color: #17479e !important;
}
.fa-user-group-icon{
  background: url("assets/images/u573.png");
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.fa-master-md-icon{
  background: url("assets/images/u583.png");
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
#confirmApprovalModal{
  top:22%;
}
@media only screen and (min-width: 320px) and (max-width: 991px)
and (orientation : portrait) {
  
  #main-content{
    margin-bottom: 20% !important;
  }
  .header-fixed .app-body{
    margin-top: -18px !important;
  }
  .dashboard-widgets{
    flex-wrap:wrap;
  }
  .widget-item{
    width:100% !important;
    margin-bottom: 20px;
  }
  .filter-block{
    flex-wrap: wrap;
    width:50% !important;
  }
  .filter-input{
    margin-bottom: 8px;
    width:50% !important;
  }
  .card-footer{
    bottom:5%
  }
  .app-footer{
    height:38px !important;
  }
  .app-footer .footer-menu{
    display:none
  }
  .app-footer .content-text-1{
    height:15px !important
  }
  .label-input{
    width:65% !important;
  }
  .label-text{
    width:100px !important
  }
}
@media only screen and (min-width: 320px) and (max-width: 991px)
and (orientation : landscape) {
  #main-content{
    margin-bottom: 20% !important;
  }
  .header-fixed .app-body{
    margin-top: -18px !important;
  }
  .app-body{
    height:calc(75vh);
  }
  .dashboard-widgets{
    flex-wrap:wrap;
  }
  .widget-item{
    width:215px;
    margin-bottom: 20px;
  }
  .filter-block{
    flex-wrap: wrap;
  }
  .filter-input{
    margin-bottom: 8px;
  }
  .card-footer{
    bottom:8%
  }
  .app-footer{
    height:30px !important;
  }
  .app-footer .footer-menu{
    display:none
  }
  .app-footer .content-text-1{
    height:15px !important
  }
  
}
.modal-backdrop.show{
  display:none;
}